import React, { useEffect, useState } from 'react'
import { deleteRequest, getRequest, postRequest } from '../../services/events'
import { machineAPI, machinesAPI, getCertificatesAPI } from '../../services/serverAddresses'
import Cookies from 'js-cookie'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom';
import Popup from '../../component/popup';
import AddMachine from './addMachine';

function MachineList(props) {
    const navigate = useNavigate();
    const [userDetail, setUserDetail] = useState(props.userDetail)
    const [machines, setMachines] = useState([])
    const [isLoader, setIsLoader] = useState(true)
    const [isUDLoader, setIsUDLoader] = useState(false)
    const [editData, setEditData] = useState(null)
    const [confirmPopup, setConfirmPopup] = useState(null)
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        if (machines.length && props.liveEvents) {
            let machines1 = [...machines]
            machines1.map(machine => {
                props?.liveEvents?.map(data => {
                    if (machine._id === data.machineId) {
                        machine.isConnected = data.connected
                    }
                })
            })
            setMachines(machines1)
        }
    }, [props.liveEvents])
    useEffect(() => {
        if (machines.length && props.machineStatus) {
            let machines1 = [...machines]
            machines1.map(machine => {
                if (machine._id === props.machineStatus.machineId) {
                    machine.isActive = props.machineStatus.isActive
                }
            })
            setMachines(machines1)
        }
    }, [props.machineStatus])
    const fetchData = async () => {
        setIsLoader(true)
        if (userDetail.role === "Admin") {
            let response = await getRequest(machinesAPI)
            setMachines(response)
        } else {
            setMachines(userDetail.machines)
        }
        setIsLoader(false)
    }
    const onDeleteHandler = async () => {
        let _id = confirmPopup._id, index = confirmPopup.index;
        let machines1 = [...machines]
        setIsUDLoader(true)
        let response = await deleteRequest(machineAPI(_id))
        props.pushAlert({ type: "warning", heading: "Delete", message: "Machine has been deleted!" })
        machines1.splice(index, 1)
        setConfirmPopup(null)
        setMachines(machines1)
        setIsUDLoader(false)
    }
    const popupOnClose = (e) => {
        setEditData(null)
        if (e) {
            let machines1 = [...machines]
            machines1.map((machine, key) => {
                if (machine._id === e._id) {
                    machines1[key] = { ...e }
                }
            })
            setMachines(machines1)
        }
    }
    const onDownloadHandler = async (machineId) => {
        setIsUDLoader(true);
        let body = {
            "machineIds": machineId
        }

        let cookies = Cookies.get('user');
        if (cookies)
            cookies = JSON.parse(cookies)
        try {
            const response = await fetch(getCertificatesAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + cookies.token
                },
                body: JSON.stringify(body)
            });

            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${machineId}.zip`);
            document.body.appendChild(link);
            link.click();
            setIsUDLoader(false);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    return (
        <div className='p-3'>
            <div className='card'>
                <div className='card-header'>
                    Machines
                </div>

                <div className='card-body'>
                    <table className="table  table-bordered">
                        <thead className="table-primary">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Machine Mac</th>
                                <th scope="col">Machine Name</th>
                                <th scope="col">No Of Boxes</th>
                                <th scope="col">Connection</th>
                                <th scope="col">Status</th>
                                <th scope="col">Created On</th>
                                {userDetail.role === "Admin" &&
                                    <th scope="col">Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoader ? machines.map((machine, key) =>
                                <tr onClick={() => userDetail.role !== "Admin" && navigate("/machine?id=" + machine._id)} style={userDetail.role !== "Admin" ? { cursor: 'pointer' } : null} key={key}>
                                    <th scope="row">{key + 1}</th>
                                    <td>{machine.mac}</td>
                                    <td>{machine.name}</td>
                                    <td>{userDetail.role === "Admin" ? machine.boxes : machine.boxes.length}</td>
                                    <td><i className={"bi bi-" + (machine.isConnected ? "wifi" : "wifi-off")} style={{ fontSize: "1.2rem" }}></i></td>
                                    <td><div className={'badge text-bg-' + (machine.isActive ? "success" : "danger")}>{(machine.isActive ? "Active" : "Deactive")}</div></td>
                                    <td>{moment(machine.created).format("DD-MMM-YYYY")}</td>
                                    {userDetail.role === "Admin" &&
                                        <td>
                                            {isUDLoader ?
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                : <>
                                                    <button onClick={() => onDownloadHandler(machine._id)} className='btn btn-sm btn-light text-primary' title='Download Certificates'><i className='fa fa-download'></i></button>
                                                    <button onClick={() => setEditData(machine)} className='btn btn-sm btn-light text-info' title='Update Machine'> <i className='fa fa-pen'></i> </button> &nbsp;
                                                    <button onClick={() => setConfirmPopup({ _id: machine._id, index: key })} className='btn btn-sm btn-light text-danger' title='Delete Machine'> <i className='fa fa-trash'></i> </button>
                                                </>}
                                        </td>
                                    }
                                </tr>
                            ) : <tr><td colSpan={7} className='text-center'><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            {editData && <Popup isCloseBtn={true} onClose={() => setEditData(null)}>
                <AddMachine {...props} onClose={popupOnClose} editMachine={editData} />
            </Popup>}
            {confirmPopup !== null && <Popup>
                <div className='w-100 mx-1' style={{ maxWidth: 600, minWidth: 300 }}>
                    <div className='card'>
                        <div className='card-header bg-danger text-light'><i className="fa-solid fa-exclamation"></i></div>
                        <div className='card-body'>
                            <h5 className='text-center py-3'>Are you sure?</h5>
                            <div className='d-flex justify-content-center' style={{ gap: 10 }}>
                                {isUDLoader ?
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    : <>
                                        <button onClick={() => setConfirmPopup(null)} style={{ width: 100 }} className='btn btn-light'><i className="fa-regular fa-face-smile"></i> No</button>
                                        <button onClick={() => onDeleteHandler()} style={{ width: 100 }} className='btn btn-danger'><i className="fa-regular fa-face-frown"></i> Yes</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>}
        </div>
    )
}

export default MachineList