import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useLocation } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { publishData } from '../../services/socket';
import { baseUrl, boxUpdateAPI, machineToggleAPI, productAPI, unassignBoxAPI } from '../../services/serverAddresses';
import { getRequest, putRequest } from '../../services/events';
import { compressBoxData } from '../../services/functions';

function Machine(props){
  let {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const param1 = searchParams.get('id');
  const [products, setProducts] = useState([])
  const [productSelect, setProductSelect] = useState(null)
  const [isLoader, setIsLoader] = useState(true)
  const [boxList, setBoxList] = useState([])
  const [machine, setMachine] = useState(null)
  const [userDetail, setUserDetail] = useState(props.userDetail)
  const [boxDetail, setBoxDetail] = useState({
    openCount: 0,
    closeCount: 0,
    outCount: 0,
    fillCount: 0,
    selectedCount: 0,
})
const [boxLoader, setBoxLoader] = useState(false)
const [readyToSet, setReadyToSet] = useState(false)
const [activeLoader, setActiveLoader] = useState(false)
const [pagination, setPagination] = useState({
    pages: 0,
    currentPage: 1,
    limit: 12
})

useEffect(() => {
    setUserDetail(props.userDetail)
    userDetail?.machines?.map(machine => {
        if(machine._id === param1 && boxList.length){
            setMachine(machine)
            // if(machine.isConnected){
            if(machine.isConnected && !machine.isActive){
                setReadyToSet(true)
            }else{
                setReadyToSet(false)
            }
            let boxList1 = [...boxList]
            let boxDetail1 = {...boxDetail}
            boxDetail1['openCount'] = 0
            boxDetail1['closeCount'] = 0
            boxDetail1['fillCount'] = 0
            let pages = machine.boxes.length/12
            let pagesFix = Math.ceil(pages)
            setPagination({...pagination,
                pages: pagesFix
            })
            machine.boxes.map((box,key)=>{
                boxList1[key] = {...box, isSelected: boxList1[key].isSelected}
                if(box.status){
                    if(box.isFilled){
                        boxDetail1['openCount'] += 1
                    }
                    
                }else{
                    boxDetail1['closeCount'] += 1
                }
                if(box.isFilled){
                    boxDetail1['fillCount'] += 1
                }
            })
            setBoxDetail(boxDetail1)
            setBoxList(boxList1)
        }
      })
}, [props.userDetail])
useEffect(() => {
    fetchProduct()
}, [])

const fetchProduct = async ()=>{
    setIsLoader(true)
    let response = await getRequest(productAPI)
    // await Promise.all(
    //   response.map(async (row,key)=> {
    //     // let newImage = await fetch(baseUrl+row.image)
    //     // newImage = await newImage.blob()
    //     // newImage = URL.createObjectURL(newImage)
    //     // response[key].image = newImage
    //     // response["isSelected"] = false
    //   })
    // )
    setProducts(response)
    setIsLoader(false)
  }
    useEffect(() => {
       userDetail?.machines?.map(async machine => {
        if(machine._id === param1){
            setMachine(machine)
            // if(machine.isConnected){
            if(machine.isConnected && !machine.isActive){
                setReadyToSet(true)
            }else{
                setReadyToSet(false)
            }
            let boxList1 = []
            let boxDetail1 = {
                openCount: 0,
                closeCount: 0,
                outCount: 0,
                fillCount: 0,
                selectedCount: 0,
            }
            setBoxLoader(true)
            let pages = machine.boxes.length/12
            let pagesFix = Math.ceil(pages)
            setPagination({
                pages: pagesFix,
                currentPage: 1,
                limit: 12
            })
            await Promise.all(
                machine.boxes.map(async box=>{
                    let box1 = {...box}
                    boxList1.push({
                        ...box1,
                        isSelected: false
                    })
                    if(box.status){
                        boxDetail1['openCount'] += 1
                    }else{
                        boxDetail1['closeCount'] += 1
                    }
                    if(box.isFilled){
                        boxDetail1['fillCount'] += 1
                    }
                })
            )
            setBoxLoader(false)
            setBoxDetail(boxDetail1)
            setBoxList(boxList1)
        }
      })
    }, [param1])
    const selectBox = (index)=>{
        let boxList1 = [...boxList]
        // if(!boxList1[index].isFilled){
        if(!boxList1[index].product){
            boxList1[index].isSelected = !boxList1[index].isSelected
            let isSelected1 = 0
            let boxDetail1 = {...boxDetail}
            boxList1.map(box=>{
                if(box.isSelected){
                    isSelected1 += 1
                }
            })
            boxDetail1['selectedCount'] = isSelected1
            setBoxDetail(boxDetail1)
            setBoxList(boxList1)
        }
    }
    const onPublishHandler = (type) => {
        let body = {
            machineId: param1,
            type: 'LOCKER',
            value: 1,
            boxes: []
        }
        let boxes = []
        if(type === "openSelected"){
            boxList.map(box=> {
                if(box.isSelected){
                    boxes.push({cabinNumber: box.cabinNumber, boxNumber: box.boxNumber})
                }
            })
            const output = [];
            
            const groupedByCabin = boxes.reduce((result, item) => {
                if (!result[item.cabinNumber]) {
                    result[item.cabinNumber] = [];
                }
                result[item.cabinNumber].push(item.boxNumber);
                return result;
            }, {});

            for (const cabinNumber in groupedByCabin) {
                output.push({
                    cabinNumber: parseInt(cabinNumber),
                    boxNumbers: groupedByCabin[cabinNumber]
                });
            }
            boxes = compressBoxData(output)
        }else if(type === "openAll"){
            boxes.push('all')
        }else if(type === "closeAll"){
            boxes.push('all')
            body["value"] = 0
        }else{
            const boxCabin = type.split("_")
            boxes = [{cabinNumber: boxCabin[0], boxNumber: boxCabin[1]}]
            const output = [];
            
            const groupedByCabin = boxes.reduce((result, item) => {
                if (!result[item.cabinNumber]) {
                    result[item.cabinNumber] = [];
                }
                result[item.cabinNumber].push(item.boxNumber);
                return result;
            }, {});

            for (const cabinNumber in groupedByCabin) {
                output.push({
                    cabinNumber: parseInt(cabinNumber),
                    boxNumbers: groupedByCabin[cabinNumber]
                });
            }
            boxes = compressBoxData(output)
        }
        body.boxes = [...boxes]
        if(body){
            publishData(body)
        }
    }
    const productAssign = async (id) => {
        setBoxLoader(true)
        let body = {};
        if(productSelect){
            body['machineId'] = param1
            if(id !== "selected"){
                body["boxIds"] = [id]
            }else{
                let boxIds = []
                boxList.map(box => {
                    if(box.isSelected && box.status){
                        boxIds.push(box._id)
                    }
                })
                body["boxIds"] = boxIds
            }
            let response = await putRequest(boxUpdateAPI(productSelect),body)
            if(response?.length){
                let userDetail1 = {...userDetail}
                let boxList1 = [...boxList] 
                response.map(data=> {
                    boxList1.map(box => {
                        box["isSelected"] = false
                        if(data._id === box._id){
                            box["product"] = data.product
                            box["productId"] = data.productId
                            box["prodcutQunatity"] = data.prodcutQunatity
                            box["updated"] = data.updated
                            box["isFilled"] = data.isFilled
                        }   
                    })
                })
                userDetail1.machines.map(machine => {
                    if(machine._id === param1){
                        machine.boxes = boxList1
                    }
                })
                props.userDetailUpdate(userDetail1)
                // setBoxList(boxList1)
            }
        }
        setBoxLoader(false)
    }
    const productUnassign = async (id) => {
        setBoxLoader(true)
        let body = {};
        let response = await putRequest(unassignBoxAPI(param1),body)
        if(!response.statusCode){
            let userDetail1 = {...userDetail}
            let boxList1 = [...boxList]
            let boxDetail1 = {...boxDetail}
            boxDetail1['selectedCount'] = 0
            boxDetail1['fillCount'] = 0
            boxList1.map(box => {
                if(box.isFilled){
                    boxDetail1['fillCount'] += 1
                }
                box["isSelected"] = false
                if(box?.productId){
                    box["productId"] = null
                    delete box["product"]
                    delete box["prodcutQunatity"]
                    delete box["updated"]
                    delete box["isFilled"]
                }   
            }) 
            setBoxDetail(boxDetail1)
            userDetail1.machines.map(machine => {
                if(machine._id === param1){
                    machine.boxes = boxList1
                }
            })
            props.userDetailUpdate(userDetail1)
        }
        setBoxLoader(false)
    }
    const wheelHandler = (e) => {
        // e.preventDefault();
    }
    const onActiveHandler = async (value) => {
      setActiveLoader(true)
      let response = await putRequest(machineToggleAPI(param1),{})
      props.pushAlert({type: "info",heading:"Information", message: "machine has been "+(!value?"activated":"deactivated")+"!"})
      setActiveLoader(false)
    }
  return (
    machine &&
    <div>
        <div className='container px-3 mb-3 justify-content-center'>
            <div className='col-xl-12 col-lg-12 card shadow border-0 rounded-3 p-3'>
                <div className='row align-items-end justify-content-between'>
                    <div className='col-lg-2 col-md-3 col-sm-3 col-5 d-flex flex-column align-items-center'>
                        <div className='col-md-12 col-sm-7 col-12'>
                            <div className='position-relative' style={{width: '100%', paddingTop: '100%'}}>
                                <div className='position-absolute w-100 h-100' style={{top:0,left:0}}>
                                    <QRCode className='bg-light rounded-3 h-100 w-100' style={{padding:10}} value={machine.qrCode} />
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex justify-content-center align-items-center w-100'>
                                <div className={'py-1 px-3 text-'+(machine.isConnected?"success":"danger")}><i className={"bi bi-"+(machine.isConnected?"wifi":"wifi-off")} style={{fontSize: "1.5rem"}}></i></div>
                                <h5 className='text-info m-0'>{machine.name}</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6 col-md-3 col-sm-9 col-7 row p-0'>
                        <div className='col-sm-12 col-12 d-flex flex-column justify-content-end' style={{gap: 10}}>
                            <b className='text-muted'>No. of Boxes: {machine.boxes.length}</b>
                            <div className='d-flex align-items-center'>
                                <div className="input-group w-auto">
                                <button onClick={()=>onActiveHandler(1)} style={{zIndex:1}} className={'btn btn-sm'+(machine.isActive? " btn-success text-light":" btn-light text-success")}> Active </button>
                                <button onClick={()=>onActiveHandler(0)} style={{zIndex:1}} className={'btn btn-sm'+(machine.isActive? " btn-light text-danger":" btn-danger text-light")}> Dective </button>
                                </div>
                                {activeLoader? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</> : null}
                            </div>
                        </div>
                        <div className='col-12'><h1>&nbsp;</h1></div>
                    </div>
                    <div className='col-xl-4 col-lg-7 col-md-6 col-12 px-2'>
                        <div className='col-12 d-flex flex-column justify-content-end' style={{gap: 10}}>
                            <div className='row g-1'>
                                <div className='col'>Box Open:</div>
                                <div className='col-sm-4 col'><button onClick={()=>onPublishHandler("openAll")} className='w-100 btn btn-sm btn-success' disabled={!readyToSet}>All</button></div>
                                <div className='col-4'><button onClick={()=>onPublishHandler("openSelected")} className='w-100 btn btn-sm btn-success' disabled={!(boxDetail.selectedCount && readyToSet)}>Selected {boxDetail.selectedCount? `(${boxDetail.selectedCount})`:""}</button></div>
                            </div>
                            {/* <div className='row g-1'>
                                <div className='col'>Box Close:</div>
                                <div className='col-sm-4 col'><button onClick={()=>onPublishHandler("closeAll")} className='w-100 btn btn-sm btn-warning text-light' disabled={!readyToSet}>All</button></div>
                                <div className='col-4'></div>
                            </div> */}
                            <div className='row g-1'>
                                <div className='col'>Add Product:</div>
                                <div className='col-sm-4 col'><button onClick={()=>productAssign("all")} className='w-100 btn btn-sm btn-primary' disabled={!(productSelect && readyToSet)?true:false}>All</button></div>
                                <div className='col-4'><button onClick={()=>productAssign("selected")} className='w-100 btn btn-sm btn-primary' disabled={!(boxDetail.selectedCount && productSelect && readyToSet)?true:false}>Selected {boxDetail.selectedCount? `(${boxDetail.selectedCount})`:""}</button></div>
                            </div>
                            <div className='row g-1'>
                                <div className='col'>Product Unassign:</div>
                                <div className='col-sm-4 col'><button onClick={()=>productUnassign("all")} className='w-100 btn btn-sm btn-warning' disabled={!(readyToSet)?true:false}>All</button></div>
                                <div className='col-4'></div>
                            </div>
                        </div>
                        <div className='col-12 d-md-block d-none'><h1>&nbsp;</h1></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row m-0 justify-content-center'>
            <div onWheel={wheelHandler} className='col-lg-6 col-md-10 col-12 d-flex align-items-center mx-0 mb-3 overflow-auto py-3'>
                {!isLoader?
                    products.map((product, key) => 
                        <div key={key} className={'col-3 h-100 position-relative'+(product._id === productSelect? " border-info":" border-light")} style={{cursor: 'pointer', borderStyle: 'solid'}} onClick={()=>setProductSelect(product._id !== productSelect? product._id: null)}>
                            <img style={{objectFit: 'contain', maxHeight: '180px'}} src={baseUrl+product.image} className="d-block w-100 h-100 pb-4" alt="..."/>
                            <div className='text-center position-absolute w-100 text-white' style={{fontWeight: 'bold',bottom: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.5)'}}>{product.price} SAR</div>
                        </div>
                    )
                :<span className="text-center pinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            </div>
        </div>
        {pagination && 
            <nav aria-label="Page navigation">
                <ul className="pagination pagination-sm justify-content-center px-3">
                    {pagination.currentPage > 1? 
                        <li className={"page-item"}>
                            <button className="page-link" onClick={()=>setPagination({...pagination, currentPage: pagination.currentPage-1})}>Previous</button>
                        </li>
                        :<li className={"page-item disabled"}>
                            <button className="page-link" tabIndex="-1">Previous</button>
                        </li>
                    }
                    <li className="page-item">
                        <button onClick={()=>setPagination({...pagination, currentPage: 1})} className={"page-link"+(pagination.currentPage === 1?" active":"")}>{1}</button>
                    </li>
                    {pagination.currentPage > 3 && <div className='px-2'>...</div>}
                    {pagination.pages > 2 && Array.from({length: pagination.pages}, (_,index) => index + 1).map(pageNo => 
                        (
                            (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages) 
                            || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1) 
                            || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                        )
                        ? 
                        <li key={pageNo} className="page-item">
                            <button onClick={()=>setPagination({...pagination, currentPage: pageNo})} className={"page-link"+(pagination.currentPage === pageNo?" active":"")}>{pageNo}</button>
                        </li>
                        : null
                    )}
                    {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div> }
                    {pagination.pages > 1 &&
                        <li className="page-item">
                            <button onClick={()=>setPagination({...pagination, currentPage: pagination.pages})} className={"page-link"+(pagination.currentPage === pagination.pages?" active":"")}>{pagination.pages}</button>
                        </li>
                    }
                    {pagination.currentPage < pagination.pages? 
                        <li className="page-item">
                            <button className="page-link" onClick={()=>setPagination({...pagination, currentPage: pagination.currentPage+1})}>Next</button>
                        </li>
                        :<li className="page-item disabled">
                            <button className="page-link" tabIndex="-1">Next</button>
                        </li>
                    }
                </ul>
            </nav>
        }
        <div className='row mx-0 mb-3 justify-content-center'>
            {pagination && boxList.map((box,key) => 
                (pagination.currentPage * pagination.limit) >= (key+1) && ((pagination.currentPage-1) * pagination.limit) <= (key) ?
                <div key={key} className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-10 mb-3'>
                    <div className='w-100 position-relative' style={{paddingTop: '100%'}}>
                        <div className='card shadow rounded-4 overflow-hidden position-absolute w-100 h-100' style={{top:0,right:0,bottom:0,left:0}}> 
                            <div onClick={()=>selectBox(key)} className={'card-header d-flex justify-content-between py-1'+(box.isSelected? " bg-primary text-light" : "")} style={{cursor: 'pointer'}}>
                                <input type='checkbox' checked={box.isSelected}/>
                                <strong>{box.name}</strong>
                                {" "+(key+1)}
                            </div>
                            <div className='card-body d-flex flex-column justify-content-between align-items-center'>
                                <div></div>
                                {/* <div className='w-100'> */}
                                    {!boxLoader? box?.product?
                                        <img width={"100%"} style={{objectFit: 'contain', minHeight: "50px", maxHeight: "85px"}} src={baseUrl+box.product.image}/>
                                        :<button className='btn border-0' onClick={()=>productAssign(box._id)} disabled={!(productSelect && readyToSet)?true:box.status?false:true}><i className='fa fa-plus' style={{fontSize: 30}}></i></button>
                                    : <div><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}
                                {/* </div> */}
                                <div className='d-flex justify-content-between w-100'>
                                    <button onClick={readyToSet && !box.status && box.productId? (() => onPublishHandler(box.cabinNumber + "_" + box.boxNumber)): null} className={((!box.status && box.productId)?'btn-success':'btn-light')+' btn px-2 py-1 rounded-5'}>Open</button>
                                    <div className={(box.isFilled?"bg-info border-info":"bg-light border-dark")+' border-1 p-1 rounded-circle'} style={{width: 35, borderStyle: 'solid'}}>&nbsp;</div>
                                    <div className={(!box.status?'bg-danger text-light':'bg-light')+' px-2 py-1 rounded-5'}>Close</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :null
            )}
        </div>
        {pagination && 
            <nav aria-label="Page navigation">
                <ul className="pagination pagination-sm justify-content-center px-3">
                    {pagination.currentPage > 1? 
                        <li className={"page-item"}>
                            <button className="page-link" onClick={()=>setPagination({...pagination, currentPage: pagination.currentPage-1})}>Previous</button>
                        </li>
                        :<li className={"page-item disabled"}>
                            <button className="page-link" tabIndex="-1">Previous</button>
                        </li>
                    }
                    <li className="page-item">
                        <button onClick={()=>setPagination({...pagination, currentPage: 1})} className={"page-link"+(pagination.currentPage === 1?" active":"")}>{1}</button>
                    </li>
                    {pagination.currentPage > 3 && <div className='px-2'>...</div>}
                    {pagination.pages > 2 && Array.from({length: pagination.pages}, (_,index) => index + 1).map(pageNo => 
                        (
                            (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages) 
                            || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1) 
                            || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                        )
                        ? 
                        <li key={pageNo} className="page-item">
                            <button onClick={()=>setPagination({...pagination, currentPage: pageNo})} className={"page-link"+(pagination.currentPage === pageNo?" active":"")}>{pageNo}</button>
                        </li>
                        : null
                    )}
                    {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div> }
                    {pagination.pages > 1 &&
                        <li className="page-item">
                            <button onClick={()=>setPagination({...pagination, currentPage: pagination.pages})} className={"page-link"+(pagination.currentPage === pagination.pages?" active":"")}>{pagination.pages}</button>
                        </li>
                    }
                    {pagination.currentPage < pagination.pages? 
                        <li className="page-item">
                            <button className="page-link" onClick={()=>setPagination({...pagination, currentPage: pagination.currentPage+1})}>Next</button>
                        </li>
                        :<li className="page-item disabled">
                            <button className="page-link" tabIndex="-1">Next</button>
                        </li>
                    }
                </ul>
            </nav>
        }
        <div className='row mx-0 position-sticky bg-light g-2 pb-2' style={{bottom:-17}}>
            <div className='col'>
                <div className='btn-group w-100 shadow' role="group" aria-label="Box Status">
                    <div className='btn px-2 py-md-2 py-0 bg-success text-light' style={{cursor: 'default'}}>Open</div>
                    <div className='btn px-2 py-md-2 py-0' style={{cursor: 'default'}}>{boxDetail.openCount}</div>
                </div>
            </div>
            <div className='col'>
                <div className='btn-group w-100 shadow' role="group" aria-label="Box Status">
                    <div className='btn px-2 py-md-2 py-0 bg-danger text-light' style={{cursor: 'default'}}>Close</div>
                    <div className='btn px-2 py-md-2 py-0' style={{cursor: 'default'}}>{boxDetail.closeCount}</div>
                </div>
            </div>
            <div className='col'>
                <div className='btn-group w-100 shadow' role="group" aria-label="Box Status">
                    <div className='btn px-2 py-md-2 py-0 bg-warning text-light' style={{cursor: 'default'}}>Out</div>
                    <div className='btn px-2 py-md-2 py-0' style={{cursor: 'default'}}>{machine.isConnected?0:boxList.length}</div>
                </div>
            </div>
            <div className='col'>
                <div className='btn-group w-100 shadow' role="group" aria-label="Box Status">
                    <div className='btn px-2 py-md-2 py-0 bg-info text-light' style={{cursor: 'default'}}>Fill</div>
                    <div className='btn px-2 py-md-2 py-0' style={{cursor: 'default'}}>{boxDetail.fillCount}</div>
                </div>
            </div>
            <div className='col'>
                <div className='btn-group w-100 shadow' role="group" aria-label="Box Status">
                    <div className='btn px-2 py-md-2 py-0 bg-secondary text-light' style={{cursor: 'default'}}>Selected</div>
                    <div className='btn px-2 py-md-2 py-0' style={{cursor: 'default'}}>{boxDetail.selectedCount}</div>
                </div>
            </div>
        </div>
        {/* <div className='d-flex justify-content-end m-0 mb-3'>
            <div className='d-flex flex-column'>
                <div className='d-flex'>
                    <div className='py-1 d-flex flex-column'>
                        <img height={50} width={80} style={{objectFit: 'contain'}} src={img1}/>
                        <div className='bg-light border-bottom border-2 border-dark py-2'>a</div>
                    </div>
                    <div className='py-1 d-flex flex-column'>
                        <img height={50} width={80} style={{objectFit: 'contain'}} src={img1}/>
                        <div className='bg-light border-bottom border-2 border-dark py-2'>a</div>
                    </div>
                    <div className='py-1 d-flex flex-column'>
                        <img height={50} width={80} style={{objectFit: 'contain'}} src={img1}/>
                        <div className='bg-light border-bottom border-2 border-dark py-2'>a</div>
                    </div>
                    <div className='py-1 d-flex flex-column'>
                        <img height={50} width={80} style={{objectFit: 'contain'}} src={img1}/>
                        <div className='bg-light border-bottom border-2 border-dark py-2'>a</div>
                    </div>
                </div>
                <div className='text-center pb-2'>Boxes without Products</div>
                <div className='d-flex w-100'>
                    <div className='col bg-light border-top border-2 border-dark py-2'>a</div>
                    <div className='col bg-light border-top border-2 border-dark py-2'>a</div>
                    <div className='col bg-light border-top border-2 border-dark py-2'>a</div>
                    <div className='col bg-light border-top border-2 border-dark py-2'>a</div>
                </div>
            </div>
        </div> */}
    </div>
  )
}

export default Machine