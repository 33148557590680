import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import './layout.css'

import Sidebar from './sidebar'
import Nav from './nav'
import Main from './main'
import Alert from '../component/alert'
import { getRequest } from '../services/events'
import { allPendingRequests, baseUrl, userAPI } from '../services/serverAddresses'
import getdata, { customerRequestAccept } from '../services/socket'
import { boxSerialDecoder } from '../services/functions'
import Popup from '../component/popup'

function Layout(props) {
  const navigate = useNavigate();
  const childFunc = useRef();
  const [userDetail, setUserDetail] = useState(null)
  const [isLoader, setIsLoader] = useState(true)
  const [liveEvents, setLiveEvents] = useState(false)
  const [machineEvents, setMachineEvents] = useState(null)
  const [machineStatus, setMachineStatus] = useState(null)
  const [purchaseRequest, setPurchaseRequest] = useState([])
  const [requestPopup, setRequestPopup] = useState(false)
  const [requestApproved, setRequestApproved] = useState(0)
  
  useEffect(() => {
      if(userDetail?.machines?.length && liveEvents){
          let userDetail1 = {...userDetail}
          userDetail1.machines.map(machine => {
              liveEvents.map(data => {
                  if(machine._id === data.machineId){
                      machine.isConnected = data.connected
                  }
              })
          })
          setUserDetail(userDetail1)
      }
  }, [liveEvents])
  useEffect(() => {
      if(userDetail?.machines?.length && machineStatus){
          let userDetail1 = {...userDetail}
          userDetail1.machines.map(machine => {
            if(machine._id === machineStatus.machineId){
                machine.isActive = machineStatus.isActive
            }
          })
          setUserDetail(userDetail1)
      }
  }, [machineStatus])
  const boxUpdateHandler = (boxes, statusName) => {
    let boxes1 = [...boxes]
    machineEvents?.boxes.map(box => {
      if(box === "all"){
          boxes1.map(oldBox => {
            if(machineEvents.type === "IR"){
              oldBox.isFilled = machineEvents.value?true:false
            }else if(machineEvents.type === "LOCKER"){
              oldBox[statusName] = machineEvents.value?true:false
              // if(machineEvents.value){
              //   oldBox["productId"] = null
              //   delete oldBox["product"]
              //   delete oldBox["prodcutQunatity"]
              //   delete oldBox["updated"]
              //   // delete oldBox["isFilled"]
              // }
            }
          })
      }else{
        let cabinBoxes = boxSerialDecoder(machineEvents.machineId,box)
        cabinBoxes.map(cbox => {
          boxes1.map(oldBox => {
            if(cbox === oldBox._id){
              if(machineEvents.type === "IR"){
                oldBox.isFilled = machineEvents.value?true:false
              }else if(machineEvents.type === "LOCKER"){
                oldBox[statusName] = machineEvents.value?true:false
                console.log(machineEvents)
                if(machineEvents.value && userDetail?.role !== "Customer" && "purchaseId" in machineEvents){
                  oldBox["productId"] = null
                  delete oldBox["product"]
                  delete oldBox["prodcutQunatity"]
                  delete oldBox["updated"]
                  // delete oldBox["isFilled"]
                }
              }
            }
          })
        })
      }
    })
    return boxes1
  }
  useEffect(() => {
    if(machineEvents){
      if(userDetail?.purchase){
        let userDetail1 = {...userDetail}
        if(machineEvents?.machineId === userDetail1.purchase.machineId && machineEvents.value){
          userDetail1.purchase.boxes = boxUpdateHandler(userDetail1.purchase.boxes, "boxStatus")
        }
        setUserDetail(userDetail1)
      }else{
        if("machines" in userDetail){
          let userDetail1 = {...userDetail}
          userDetail1?.machines?.map(machine => {
            if(machineEvents?.machineId === machine._id && typeof machine.boxes === "object"){
              machine.boxes = boxUpdateHandler(machine.boxes, "status")
            }
            return machine
          })
          setUserDetail(userDetail1)
        }
      }
    }
  }, [machineEvents])
  useEffect(() => {
    let cookies = Cookies.get('user')
    if(!cookies){
      navigate("/login")
    }else{
      cookies = JSON.parse(cookies)
      fetchData(cookies)
      let client = getdata();
      client.on("Events", body => {
        if(body.type === "Connections"){
          setLiveEvents(body.data)
        }else if(body.type === "MachineStatus"){
          setMachineStatus(body.data)
        }else if(body.type === "PaymentRequests"){
          if(cookies?.role === "Admin"){
            setPurchaseRequest(data => [...data, body.data])
            setRequestPopup(true)
          }
        }else if(body.type === "PaymentRequestsResponse"){
          if(cookies?.role === "Customer" && cookies?._id === body.data.customerId){
            if(body.data.status === "PaymentRejected"){
              setRequestApproved(2)
              setTimeout(() => {setRequestApproved(0); navigate("/") }, 1000)
            }else if(body.data.status === "PaymentDone"){
              setRequestApproved(body.data)
            }
          }
        }else{
          setMachineEvents(body.data)
        }
      })

    }
  }, [])
  const fetchData = async (cookie) => {
    setIsLoader(true)
    let response = await getRequest(userAPI(cookie._id))
    if(response.purchase){
      response.machines = [response.purchase.machine]
    }
    if(cookie?.role === "Admin"){
      const requestResponse = await getRequest(allPendingRequests)
      setPurchaseRequest(requestResponse)
    }
    setUserDetail({...cookie, ...response})
    if(response.purchase){
      navigate("/customerpage")
    }
    setIsLoader(false)
  }
  const alertHandler = (data) => {
    childFunc.current.alertPush(data)
  }
  const userDetailUpdate = (e) => {
    setUserDetail(e)
  }
  return (
    isLoader? 
      <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</>
      :
      userDetail &&
    <div className='d-flex' style={{height: '100vh'}}>
      
      <>
      {userDetail.role !== "Customer" && <Sidebar userDetail={userDetail}/>}
      <div className='d-flex flex-column col'>
        <Nav purchaseRequestPopup={()=>setRequestPopup(true)} purchaseRequest={purchaseRequest} userDetail={userDetail}/>
        <Alert ref={childFunc}/>
        <Main
          pushAlert={alertHandler}
          userDetail={userDetail}
          liveEvents={liveEvents}
          machineStatus={machineStatus}
          userDetailUpdate={userDetailUpdate}
          requestApproved={requestApproved}
        />
        {requestPopup?
          <Popup>
            <div className='card col-8'>
              <div className='card-header d-flex justify-content-center align-items-center'>
                <h3 className='w-100 m-0'>Purchase Request</h3>
                <button onClick={()=> setRequestPopup(false)} className='btn btn-danger'>
                  <i className='fa fa-times'></i>
                </button>
              </div>
              <div className='card-body pb-0'>
                <div className='row'>
                  {purchaseRequest.length? purchaseRequest.map((requestData,key)=>
                    <div key={key} className='col-md-4 mb-3'>
                      <div className='card shadow rounded-4 overflow-hidden'> 
                        <div className={'card-header'}>
                          <h5 className='m-0'>Customer Name: <b>{requestData.customerName}</b></h5>
                          <div>Customer Id: <b>{requestData.customerId}</b></div>
                          <h5 className='m-0'>Machine Name: {requestData?.machine?.name}</h5>
                        </div>
                        <div className='card-body text-center'>
                            <h2>{requestData?.price}<sub style={{fontSize: 15}}>SAR</sub></h2>
                            <h5>Total Purchase</h5>
                        </div>
                        <div className='card-footer d-flex justify-content-center' style={{gap: 10}}>
                          <button onClick={()=> {
                            let purchaseRequest1 = [...purchaseRequest]
                            purchaseRequest1.splice(key,1) 
                            setPurchaseRequest(purchaseRequest1);
                            customerRequestAccept({
                              _id: requestData._id,
                              customerId: requestData.customerId,
                              status: 'PaymentRejected'
                            })
                          }} className='btn btn-danger'>Cancel</button>
                          <button className='btn btn-success' onClick={()=> {
                            let purchaseRequest1 = [...purchaseRequest]
                            purchaseRequest1.splice(key,1) 
                            if(!purchaseRequest1.length){
                              setRequestPopup(false)
                            }
                            setPurchaseRequest(purchaseRequest1);
                            customerRequestAccept(requestData)
                          }}>Approved</button>
                        </div>
                      </div>
                    </div>
                    ):
                    <div className='p-3 pb-4 text-center text-muted' style={{fontSize: '30px', fontWeight: 'bold'}}>
                      Request Not Found
                    </div>
                  }
                </div>
              </div>
            </div>
          </Popup>
        :null}
      </div>
      </>
    </div>
  )
}

export default Layout