import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing'
import { machineQRScane } from '../../services/serverAddresses';
import { getRequest } from '../../services/events';
import CustomerPage from './customerPage';
import qrcodeParser from "qrcode-parser";

export default function MachineScan(props) {
  const navigate = useNavigate();
  return <div className='d-flex justify-content-center align-items-center h-100'><button onClick={()=>navigate("/machinescanner")} className='btn btn-light btn-lg text-primary shadow p-3'><i className="fa-solid fa-qrcode fa-xl pb-4"></i><br/>Scan Machine</button></div>
}

export function MachineScanner(props){
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [selectImg, setSelectImg] = useState(null);
  const [message, setMessage] = useState({type: "light",text:"Scan Here"});
  // const [machine, setMachine] = useState(null);
  let {ref} = useZxing({
    onDecodeResult(result) {
      // setResult(result.getText());
      let data = result.getText()
      if(!isLoader){
        if(data.length <= 5 && data.charAt(0) === "M"){
          onSearchMachine(data)
        }else{
          setMessage({type: "danger",text:"Invalid QR-Code"})
        }
      }
    },
  });
  const onSearchMachine = async (data) => {
    setIsLoader(true)
    setMessage({type: "info",text:"Scan Here"})
    let response = await getRequest(machineQRScane(data))
    if(!response.statusCode){
      if(response.isConnected){
        if(response.isActive){
          setMessage({type: "success",text:"Machine Detected!"})
          setTimeout(() => {
            let userDetail = {...props.userDetail}
            userDetail.machines = [response]
            props.userDetailUpdate(userDetail)
            setMessage({type: "light",text:"Scan Here!"})
            navigate("/customerpage")
            // setMachine(userDetail)
          }, 1000);
        }else{
          setMessage({type: "danger",text:"Machine Is Not Active!"})
        }
      }else{
        setMessage({type: "danger",text:"Machine Is Offline!"})
      }
    }else{
      setMessage({type: "danger",text:"Machine Not Found!"})
      setIsLoader(false)
    }
    setIsLoader(false)
  }
  const imageUpload = async (e) => {
    const file = e.target.files[0]
    if(file){
      if(file.type.endsWith("jpg") || file.type.endsWith("jpeg") || file.type.endsWith("png")){
        setSelectImg({value: e.target.value, src: URL.createObjectURL(file)})
        // document.getElementById('qrArea').innerHTML = `
        // <img src='${URL.createObjectURL(file)}' width='100%' height='100%'/>
        // `
        try {
          const result = await qrcodeParser(file);
          setTimeout(() => { onSearchMachine(result) }, 1000)
        } catch (error) {
          setMessage({type: "danger",text:"Invalid Image"})
        }
      }else{
        setMessage({type: "danger",text:"Invalid Image"})
      }
    }
  }
  return (    
    // onClick={()=>onSearchMachine("M001")}
    <div className='position-relative bg-dark' style={{height:"100%"}}>
      <video className='m-0 p-0' style={{width:"100%", height: "100%"}} ref={ref} />
      <div className='position-absolute d-flex justify-content-center align-items-center' style={{top: 0, right: 0, bottom: 0, left: 0}} >
        <div id="qrArea" className={'border-2 position-relative rounded-3 overflow-hidden border-'+message.type} style={{backgroundColor: message.type !== "light" && message.type !== "info"? 'rgba(255,255,255,0.7)': 'transparent' ,width: '30vw', height: '30vw', minWidth: 200, maxWidth: 300, minHeight: 200, maxHeight: 300, borderStyle: 'solid'}}>
          {selectImg?
          <>
            <img width="100%" height="100%" style={{filter: message.type !== 'light'? 'brightness(0.5)' : 'brightness(1)'}} src={selectImg.src}/>
            <i className='fa fa-times-circle fa-xl text-danger position-absolute' style={{top: '20px', right: '10px'}} onClick={()=> {setSelectImg(null);setMessage({type: "light",text:"Scan Here!"})}}></i>
          </>
          :null}
          <h5 className={'position-absolute text-center text-'+message.type} style={{top:"50%",left:"50%",transform:'translate(-50%,-50%)', minWidth: 150}}>{!isLoader?message.text:<div className='d-flex flex-column justify-content-center align-items-center'><span className="spinner-border" role="status" aria-hidden="true"></span> <small className='w-100'>Fetching Data</small></div>}</h5>
        </div>
        <label className='m-0 btn btn-dark position-absolute' style={{bottom: '100px'}}><i className='fa fa-image'></i> Scan from image <input onChange={imageUpload} value={selectImg?.value || null} type="file"  accept="image/*" hidden/></label>
      </div>
    </div>
  )
}
