import Cookies from 'js-cookie'
import React from 'react'
import { useNavigate } from 'react-router-dom';

function Nav(props) {
  const navigate = useNavigate();
  const onLogoutHandler = () => {
    Cookies.remove('user')
    navigate("/login")
  }
  return (
    <nav className='d-flex justify-content-between align-items-center p-2 shadow' style={{zIndex:'1'}}>
      {props.userDetail.role === "Customer"?
      <div className="h5 m-0 p-3 text-primary">Customer</div>
      // <div className="h5 m-0 p-3 text-primary">{props.userDetail.name}</div>
      :<button className='btn btn-primary' data-bs-toggle="collapse" data-bs-target="#mySideBar" aria-expanded="false" aria-controls="mySideBar">
        <i className="fa fa-bars"></i>
      </button>}
      <div className='d-flex' style={{gap: 5}}>
      {props.userDetail.role === "Admin" &&
        <button onClick={() => props.purchaseRequestPopup()} title="Purchase Request" className='btn btn-light text-info position-relative'>
          <i className="far fa-bell"></i>
          {props.purchaseRequest && props.purchaseRequest?.length ?
            <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
              <span className="visually-hidden">New alerts</span>
            </span>
          :null}
        </button>}
        <button title="Log Out" onClick={onLogoutHandler} className='btn text-danger'  data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
        </button>
      </div>
    </nav>
  )
}

export default Nav