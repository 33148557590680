import { io } from "socket.io-client";
import { socketAddress } from "./serverAddresses";
import Cookies from "js-cookie";
let socket

export async function publishData(data) {
    if (socket) {
        socket.emit("ControlRequest", data)
    }
}
export async function customerPublishData(data) {
    if (socket) {
        socket.emit("ControlRequest2", data)
    }
}
export async function customerRequestAccept(data) {
    if (socket) {
        socket.emit("RequestsResponse", data)
    }
}
export default function getdata() {
  if (socket) {
    socket.disconnect();
  }
  let cookies = Cookies.get('user')
  let token
  if(cookies){
      cookies = JSON.parse(cookies)
      token = cookies.token;
  }
  socket = io(socketAddress, {
    auth: { token },
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    console.log("connected");
  });
  return socket;
}