import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./container/login/login";
import Layout from "./layout/layout";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<Layout/>}/>
      <Route exact path="login" element={<Login/>} />
      <Route exact path="registration" element={<Login/>} />
    </Routes>
  );
}

export default App;
