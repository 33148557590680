import React, { useEffect, useState } from 'react'

function Popup(props) {
    const [isCloseBtn, setIsCloseBtn] = useState(false)
    useEffect(() => {
        if("isCloseBtn" in props){
            setIsCloseBtn(props.isCloseBtn)
        }
    }, [])
    
  return (
    <div className='position-fixed d-flex justify-content-center align-items-center' style={{top:0,left:0,bottom:0,right:0,zIndex:10,backgroundColor:'rgba(0,0,0,0.5)'}}>
        {isCloseBtn && 
        <>
            <div className='position-absolute w-100 h-100' onClick={()=>props.onClose(false)}></div>
            <button className='btn btn-lg text-white position-absolute' onClick={()=>props.onClose(false)} style={{right:0,top:0,zIndex:2}}><i className='fa fa-times fa-xl'></i></button>
        </>
        }
        <div className='w-100 d-flex justify-content-center' style={{zIndex:1}}>
            {props.children}
        </div>
    </div>
  )
}

export default Popup