import React, { forwardRef, useImperativeHandle, useState } from 'react'

function Alert(props,ref) {
    const push = (data)=> {
        showAlert(data.type, data.heading, data.message)
    }
    useImperativeHandle(ref, () => ({
        // alertPush: push,
        alertPush: push,
    }));
    function removeAlert() {
        const alertContainer = document.getElementById('alertContainer');
        const alertElement = alertContainer.querySelector('.alert');
        
        if (alertElement) {
          alertElement.remove();
        }
    }
      
    // Function to show the alert and schedule its removal
    const showAlert = (type, heading, message) => {
    const alertContainer = document.getElementById('alertContainer');
    
    // Create the alert element
    const alertElement = document.createElement('div');
    alertElement.setAttribute('class',`alert alert-${type} alert-dismissible fade show m-0`);
    alertElement.innerHTML = `
        <strong>${heading}</strong> ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    `;
    alertContainer.appendChild(alertElement);
    setTimeout(() => {
        removeAlert();
    }, 2000);
    }
  return <div id="alertContainer"></div>
}

export default forwardRef(Alert)