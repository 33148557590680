import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getCustomerAPI, getVendorsAPI, unassignMachineAPI, userAPI, userToggleAPI } from '../../services/serverAddresses';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../services/events';
import moment from 'moment';
import Popup from '../../component/popup';

function AdminList(props) {
  let {pathname} = useLocation();
  const [users, setUsers] = useState([])
  const [isLoader, setIsLoader] = useState(true)
  const [isUDLoader, setIsUDLoader] = useState(false)
  const [confirmPopup, setConfirmPopup] = useState(null)
  useEffect(() => {
      fetchData();
  }, [pathname])
  const fetchData = async() => {
    setIsLoader(true)
    let response = await getRequest(pathname === "/adminlist"? getVendorsAPI: getCustomerAPI)
    setUsers(response)
    setIsLoader(false)
  }
  const disconnectHandler = async (_id,index,index1) => {
    setIsUDLoader(true)
    let users1 = [...users]
    let response = await putRequest(unassignMachineAPI(_id,null))
    props.pushAlert({type: "info",heading:"Information", message: "Machine has been disconnected!"})
    users1[index].machines.splice(index1,1)
    setUsers(users1)
    setIsUDLoader(false)
  }
  const onDeleteHandler = async () => {
    let _id = confirmPopup._id, index = confirmPopup.index;
    setIsUDLoader(true)
    let users1 = [...users]
    let response = await deleteRequest(userAPI(_id))
    props.pushAlert({type: "warning",heading:"Delete", message: (pathname === "/addadmin"? "Admin Machine" : "Customer ")+" has been deleted!"})
    users1.splice(index,1)
    setUsers(users1)
    setConfirmPopup(null)
    setIsUDLoader(false)
  }
  const onActiveHandler = async (_id,index,value) => {
    setIsUDLoader(true)
    let users1 = [...users]
    let response = await putRequest(userToggleAPI(_id),{})
    props.pushAlert({type: "info",heading:"Information", message: (pathname === "/addadmin"? "Admin Machine" : "Customer ")+" has been "+(!value?"activated":"deactivated")+"!"})
    users1[index].isActive = response.isActive
    setUsers(users1)
    setIsUDLoader(false)
  }
  return (
    <div className='p-3'>
        <div className='card'>
            <div className='card-header'>
                All {pathname === "/adminlist"? "Admin " : "Customer "}
            </div>
            
            <div className='card-body'>
            {/* table-striped */}
                <table className="table  table-bordered">
                    <thead className="table-primary">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          {/* <th scope="col">Status</th> */}
                          <th scope="col">Created On</th>
                          <th scope="" style={{width: "200px"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoader? users.map((user,key)=>
                        <React.Fragment key={key}>
                          <tr style={user?.machines?.length?{cursor:'pointer'}:null} data-bs-toggle="collapse" data-bs-target={"#machineList_"+user._id} aria-expanded="false" aria-controls={"#machineList_"+user._id}>
                            <th scope="row">{user?.machines?.length? <i className='fa fa-angle-right'></i>: null} {key + 1}</th>
                            <td>{user.name}</td>
                            <td>{user._id}</td>
                            {/* <td><div className='badge text-bg-success'>{user.isActive ? "Active": "Deactive"}</div></td> */}
                            <td>{moment(user.created).format("DD-MMM-YYYY")}</td>
                            <td>
                              {isUDLoader?
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              :<div className='d-flex'>
                                <div className="input-group w-auto">
                                  <button onClick={()=>onActiveHandler(user._id,key,1)} className={'btn btn-sm'+(user.isActive? " btn-success text-light":" btn-light text-success")}> Active </button>
                                  <button onClick={()=>onActiveHandler(user._id,key,0)} className={'btn btn-sm'+(user.isActive? " btn-light text-danger":" btn-danger text-light")}> Dective </button>
                                </div> &nbsp;
                                <button onClick={()=>setConfirmPopup({_id: user._id, index: key})} className='btn btn-sm btn-light text-danger'> <i className='fa fa-trash'></i> </button>
                              </div>}
                            </td>
                          </tr>
                          {user?.machines?.length?
                            <tr className="collapse" id={"machineList_"+user._id}>
                              <td colSpan="6">
                                <table className="table shadow rounded-4 overflow-hidden">
                                    <thead className="table-light">
                                        <tr>
                                          <th scope="col">Machine Name</th>
                                          <th scope="col">Machine Mac</th>
                                          <th scope="col">No. Of Box</th>
                                          <th scope="">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="rounded-3">
                                      {user?.machines.length && user.machines.map((machine, key1) =>
                                          <tr key={key1}>
                                            <td>{machine.name}</td>
                                            <td>{machine.mac}</td>
                                            <td>{machine.boxes.length}</td>
                                            <td>
                                              {isUDLoader?
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                              :
                                                <button onClick={()=>disconnectHandler(machine._id,key,key1)} className='btn btn-sm btn-light text-warning' title='Disconnect'> <i className='fa fa-link-slash'></i> </button>
                                              }
                                            </td>
                                          </tr>
                                      )}
                                    </tbody>
                                </table>
                              </td>
                            </tr>
                          :null}
                        </React.Fragment>
                        ): <tr><td colSpan={7} className='text-center'><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
        {confirmPopup !== null && <Popup>
            <div className='w-100 mx-1' style={{maxWidth: 600, minWidth: 300}}>
                <div className='card'>
                    <div className='card-header bg-danger text-light'><i className="fa-solid fa-exclamation"></i></div>
                    <div className='card-body'>
                        <h5 className='text-center py-3'>Are you sure?</h5>
                        <div className='d-flex justify-content-center' style={{gap: 10}}>
                        {isUDLoader?
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        :<>
                            <button onClick={()=> setConfirmPopup(null)} style={{width: 100}} className='btn btn-light'><i className="fa-regular fa-face-smile"></i> No</button>
                            <button onClick={()=> onDeleteHandler()} style={{width: 100}} className='btn btn-danger'><i className="fa-regular fa-face-frown"></i> Yes</button>
                        </>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </Popup>}
    </div>
  )
}

export default AdminList