import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { baseUrl, purchasesAPI } from '../../services/serverAddresses'
import { postRequest } from '../../services/events'
import { useNavigate } from 'react-router-dom';
import { customerPublishData, publishData } from '../../services/socket';
import { compressBoxData } from '../../services/functions';
import PaymentProcess from './paymentProcess';
import Popup from '../../component/popup';

function CustomerPage(props) {
    const navigate = useNavigate();

    const [machine, setMachine] = useState(null)
    const [products, setProducts] = useState([])
    const [items, setItems] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [purcahsedItems, setPurcahsedItems] = useState(null)
    const [isLoader, setIsLoader] = useState(false)
    const [readyToSet, setReadyToSet] = useState(false)
    const [isPaymentProcess, setIsPaymentProcess] = useState(false)
    const [isWaiting, setIsWaiting] = useState(0)

    useEffect(() => {
        if (props?.userDetail) {
            if (!("machines" in props.userDetail)) {
                navigate("/machinescan")
                return
            }
            let machine = props.userDetail?.machines[0]
            if (machine.isConnected && machine.isActive) {
                setReadyToSet(true)
            } else {
                setReadyToSet(false)
            }
            setMachine(machine)
            if (!props.userDetail.purchase && !machine?.products) {
                navigate("/machinescan")
            }
            if (machine?.products) {
                let products = [...machine.products]
                setProducts(products)
            }
            if (props.userDetail?.purchase) {
                let purshaseItems1 = { ...props.userDetail.purchase }
                let openCount = 0
                purshaseItems1.boxes.map(item => {
                    if (item.boxStatus) {
                        openCount += 1
                    }
                })
                purshaseItems1['openCount'] = openCount
                if (purshaseItems1.status === "PaymentDoneRequest") {
                    setIsWaiting(1)
                } else {
                    setPurcahsedItems(purshaseItems1)
                }
            }
        }
        const handleFocus = () => {
            if (props.userDetail.purchase) {
                window.location.reload();
            }
        };
        window.addEventListener('focus', handleFocus);
        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [props, props.userDetail])
    const onSelectItems = (_id, sumType, boxes, price) => {
        let items1 = [...items]
        let newItem = {
            productId: "",
            boxId: [],
            quantity: 0,
            price: 0,
        }
        let index = null
        items.map((item, key) => {
            if (item.productId === _id) {
                newItem = item
                index = key
            }
        })
        newItem['productId'] = _id
        newItem['price'] = price
        if (sumType === "add" && boxes.length - newItem.quantity > 0) {
            newItem['quantity'] += 1
            newItem['boxId'].push(boxes[newItem.quantity - 1]._id)
        } else if (sumType === "minus" && newItem.quantity > 0) {
            newItem['quantity'] -= 1
            newItem['boxId'].splice(newItem.quantity - 1, 1)
        }
        if (index !== null) {
            if (newItem.quantity) {
                items1[index] = newItem
            } else {
                items1.splice(index, 1)
            }
        } else if (newItem.quantity) {
            items1.push(newItem)
        }
        let totalPrice1 = 0
        items1.map(item => {
            totalPrice1 += item.price
        })
        setTotalPrice(totalPrice1)
        setItems(items1)
    }
    const onPurchaseHandler = async () => {
        setIsLoader(true)
        let restructureItems = []
        items.map(item => {
            item.boxId.map(boxId => {
                restructureItems.push({
                    productId: item.productId,
                    boxId,
                    boxStatus: false
                })
            })
        })
        let body = {
            customerId: props.userDetail._id,
            machineId: machine._id,
            machine,
            price: totalPrice,
            items: restructureItems,
        }
        let response = await postRequest(purchasesAPI, body)
        // response.PaymentDoneRequest
        setIsWaiting(1)
        setIsLoader(false)
    }
    useEffect(() => {
        if (props.requestApproved !== 0 && props.requestApproved !== 2) {
            setIsWaiting(0)
            onRequestAccept(props.requestApproved)
        } else if (props.requestApproved === 2) {
            setIsWaiting(2)
            props.pushAlert({ type: "danger", heading: "Sorry", message: "Payment process has been rejected!" })
        } else {
            setIsWaiting(0)
        }
    }, [props.requestApproved])

    const onRequestAccept = (data) => {
        let openCount = 0
        data.boxes.map(item => {
            if (item.boxStatus) {
                openCount += 1
            }
        })
        data['openCount'] = openCount
        setPurcahsedItems(data)
        let userDetail = { ...props.userDetail }
        userDetail['purchase'] = data
        props.userDetailUpdate(userDetail)
    }
    const onPublishHandler = (cabinNumber, boxNumber) => {
        let boxex = compressBoxData([{ cabinNumber: cabinNumber, boxNumbers: [boxNumber] }])
        let body = {
            purchaseId: purcahsedItems._id,
            machineId: machine._id,
            value: 1,
            type: 'LOCKER',
            boxes: boxex
        }
        publishData(body)
    }
    return (
        machine &&
        <div className='py-3'>
            {isWaiting ?
                <Popup>
                    <div className='card col-md-6'>
                        <h4 className='card-body text-center'>
                            {isWaiting === 1 ?
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><br />
                                    Please wait for approve!
                                </>
                                :
                                null
                            }
                        </h4>
                    </div>
                </Popup>
                : null}

            <div className='d-flex flex-column justify-content-center align-items-center mx-0' style={{ gap: 10 }}>
                <div className='bg-light rounded p-3'>
                    <QRCode style={{ width: 100, height: 100 }} value={machine.qrCode} />
                </div>
                <div className='d-flex align-items-start justify-content-center mx-0 mb-3 bg-light rounded'>
                    <div className={'py-1 px-3 text-' + (machine.isConnected ? "success" : "danger")}><i className={"bi bi-" + (machine.isConnected ? "wifi" : "wifi-off")} style={{ fontSize: "1.5rem" }}></i></div>
                    <h4 className=' py-2 px-3 text-primary m-0'>{machine.name}</h4>
                </div>
            </div>
            <div className='container'>
                <div className='row justify-content-center mb-3'>
                    {purcahsedItems && purcahsedItems.boxes.length === purcahsedItems.openCount ?
                        <div className='d-flex flex-column align-items-center mb-3'>
                            <h3>Hooray! All Boxes Are Opened</h3>
                            <button className='btn btn-warning' onClick={() => window.location.href = "/machinescan"}>Back To Continue Shopping</button>
                        </div> : null}
                    {!purcahsedItems ?
                        // !isPaymentProcess?
                        products.length ?
                            products.map((product, key) =>
                                <div key={key} className='col-lg-3 col-md-4 col-sm-6 mb-3'>
                                    <div className='card shadow rounded-4'>
                                        <div className={'card-header text-center'}>
                                            <img height={150} width="100%" style={{ objectFit: 'contain' }} src={baseUrl + product.image} />
                                            <h4 className='mt-2 mb-0 text-center'>{product.productName}</h4>
                                        </div>
                                        <div className='card-body'>
                                            <div className='row m-0 bg-light p-2 rounded-4 mb-3'>
                                                <div className='col-6'>Available</div>
                                                <b className='col-6'>{product.boxes.length}</b>
                                                <div className='col-6'>Price</div>
                                                <b className='col-6'>{product.price} SAR</b>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <button onClick={() => onSelectItems(product._id, 'minus', product.boxes, product.price)} className='btn btn-light btn-lg text-danger'><i className='fa fa-minus'></i></button>
                                                {items.map((item, key) =>
                                                    item.productId === product._id &&
                                                    <b key={key} className='bg-light btn-lg rounded-5 d-flex align-items-center px-3 text-muted'> {item.quantity}
                                                        {/* =/ {item.quantity * product.price} SAR  */}
                                                    </b>
                                                )}
                                                <button onClick={() => onSelectItems(product._id, 'add', product.boxes, product.price)} className='btn btn-light btn-lg text-success'><i className='fa fa-plus'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : <h3 className='text-center text-muted py-5'>Product not found!</h3>
                        // :<PaymentProcess/>
                        :
                        //  purcahsedItems.boxes.length !== purcahsedItems.openCount?
                        purcahsedItems.boxes.map((item, key) => <div key={key} className='col-lg-3 col-md-4 col-sm-6 mb-3'>
                            <div className='card shadow rounded-4'>
                                <div className={'card-header'}>
                                    <b className='text-info fload-right'>{item.name}</b>
                                </div>
                                {item?.product ?
                                    <div className='card-body text-center'>
                                        <img height={150} width="100%" style={{ objectFit: 'contain' }} src={baseUrl + item.product.image} />
                                        <h4 className='mt-2 mb-0 text-center'>{item.product.name}</h4>
                                    </div>
                                    : null}
                                <div className='card-footer text-center'>
                                    {!(item.boxStatus) ?
                                        <button onClick={() => onPublishHandler(item.cabinNumber, item.boxNumber)} className='btn btn-success'>Open</button>
                                        : <button className='btn btn-light text-success'>Opened</button>}
                                </div>
                            </div>
                        </div>)}
                </div>
            </div>
            {!purcahsedItems ?
                <div className='d-flex flex-column align-items-center' style={{ gap: 10 }}>
                    <div className='shadow py-2 px-3 rounded'>Total Amount: <b>{totalPrice} SAR</b></div>
                    {!isLoader ?
                        <button onClick={onPurchaseHandler} className='btn btn-lg btn-warning' disabled={!(items.length && readyToSet) ? true : false}>Check Out And Pay</button>
                        // <button onClick={()=>setIsPaymentProcess(true)} className='btn btn-lg btn-warning'>Check Out And Pay</button>
                        : <button className='btn btn-lg btn-warning'><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait...</button>
                    }
                </div>
                :
                <div className='container'>
                    <div className='d-flex justify-content-between'>
                        {/* <h4 className='shadow p-3 rounded text-success'>Ready to open: <b>{purcahsedItems.openCount}</b></h4> */}
                        <h4 className='shadow p-3 rounded text-success'>Ready to open: <b>{purcahsedItems.boxes.length - purcahsedItems.openCount}</b></h4>
                        <h4 className='shadow p-3 rounded text-success'>Opened: <b>{purcahsedItems.openCount}</b></h4>
                        <h4 className='shadow p-3 rounded text-danger'>Remaining: <b>{purcahsedItems.boxes.length - purcahsedItems.openCount}</b></h4>
                    </div>
                </div>
            }
        </div>)
}

export default CustomerPage 