import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Sidebar(props) {
  const navigate = useNavigate();
  let {pathname,search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const param1 = searchParams.get('id');
  const [userDetail, setUserDetail] = useState(props.userDetail)
  const [winSize, setWinSize] = useState()
  useLayoutEffect(() => {
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    }
  }, [])
  const checkScreenWidth = () => {
    setWinSize(window.innerWidth)
  }
  
  return (
    <div className={"h-100 collapse collapse-horizontal shadow overflow-auto bg-white "+(winSize <= 768? " position-absolute":" show")} id="mySideBar" style={{zIndex:'3',width: 250,minWidth: 250}}>
      {/* <div style={{}}> */}
        <div className="h5 m-0 text-primary py-2 d-flex align-items-center justify-content-between">
          {/* <span className="px-3">{userDetail.name}</span> */}
          <span className="px-3">{userDetail.role === "Admin"? "Super Admin" :userDetail.role === "Custonmer"? "Customer":"Machine Admin"}</span>
          <button className="btn" data-bs-toggle="collapse" data-bs-target="#mySideBar" aria-expanded="false" aria-controls="mySideBar"><i className="fa fa-times"></i></button>
        </div>
      {userDetail.role === "Admin"?
        <>
          <div onClick={()=>navigate("/machines")} className={"sideBarButton"+(pathname === "/machines"?" active": "")}>All Machine</div>
          <div onClick={()=>navigate("/addmachine")} className={"sideBarButton"+(pathname === "/addmachine"?" active": "")}>Add Machine</div>
          <div onClick={()=>navigate("/adminlist")} className={"sideBarButton"+(pathname === "/adminlist"?" active": "")}>All Machine Admin</div>
          <div onClick={()=>navigate("/addadmin")} className={"sideBarButton"+(pathname === "/addadmin"?" active": "")}>Add Machine Admin</div>
          <div onClick={()=>navigate("/customerlist")} className={"sideBarButton"+(pathname === "/customerlist"?" active": "")}>All Customer</div>
          {/* <div onClick={()=>navigate("/addcustomer")} className={"sideBarButton"+(pathname === "/addcustomer"?" active": "")}>Add Customer</div> */}
          <div onClick={()=>navigate("/productlist")} className={"sideBarButton"+(pathname === "/productlist"?" active": "")}>All Product</div>
          <div onClick={()=>navigate("/addproduct")} className={"sideBarButton"+(pathname === "/addproduct"?" active": "")}>Add Product</div>
          <div onClick={()=>navigate("/connectmachine")} className={"sideBarButton"+(pathname === "/connectmachine"?" active": "")}>Connect Machine</div>
        </>
          :
        <>
          <div onClick={()=>navigate("/machines")} className={"sideBarButton"+(pathname === "/machines"?" active": "")}>All My Machines</div>
          {userDetail.machines.map((machine,key)=>
            <div key={key} onClick={()=>navigate("/machine?id="+machine._id)} className={"sideBarButton"+(param1 === machine._id?" active": "")}>{machine.name}</div>
          )}
        </>
      }
      {/* </div> */}
    </div>
  );
}

export default Sidebar;
