import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import MachineList from '../container/machine/machineList'
import AddMachine from '../container/machine/addMachine'
import AdminList from '../container/machineAdmin/adminList'
import AddAdmin from '../container/machineAdmin/addAdmin'
import ProductList from '../container/product/productList'
import AddProduct from '../container/product/addProduct'
import ConnectMachine from '../container/machine/connectMachine'
import Machine from '../container/machine/machine'
import CustomerPage from '../container/machine/customerPage'
import MachineScan, { MachineScanner } from '../container/machine/machineScan'
// import PaymentProcess from '../container/machine/paymentProcess'


function Main(props) {
    let {pathname} = useLocation();
    const routes = props?.userDetail?
    props?.userDetail?.role === "Admin"?
    [
        { path: '/', exact: true, name: 'Machines',  component: MachineList },
        { path: 'machines', exact: true, name: 'Machines',  component: MachineList },
        { path: 'addmachine', exact: true, name: 'Add Machine',  component: AddMachine },
        { path: 'connectmachine', exact: true, name: 'Connect Machine',  component: ConnectMachine },
        { path: 'adminlist', exact: true, name: 'Admin List',  component: AdminList },
        { path: 'addadmin', exact: true, name: 'Add Admin',  component: AddAdmin },
        { path: 'customerlist', exact: true, name: 'Customer List',  component: AdminList },
        // { path: 'addcustomer', exact: true, name: 'Add Customer',  component: AddAdmin },
        { path: 'productlist', exact: true, name: 'Product List',  component: ProductList },
        { path: 'addproduct', exact: true, name: 'Add Product',  component: AddProduct },
    ]
    :props?.userDetail?.role === "Vendor"?
    [
        { path: '/', exact: true, name: 'Machine',  component: Machine },
        { path: 'machine', exact: true, name: 'Machine',  component: Machine },
        { path: 'machines', exact: true, name: 'Machines',  component: MachineList },
    ]
    :[
        { path: '/', exact: true, name: 'Machine Scan',  component: MachineScan },
        { path: 'machinescan', exact: true, name: 'Machine Scan',  component: MachineScan },
        { path: 'machinescanner', exact: true, name: 'Machine Scanner',  component: MachineScanner },
        // { path: 'paymentProcess', exact: true, name: 'Payment Process',  component: PaymentProcess },
        { path: 'customerpage', exact: true, name: 'Customer Page',  component: CustomerPage },
    ]
    :[]
    
    const [showScrollButton, setShowScrollButton] = useState(false);
    useEffect(() => {
      // Show the button when user has scrolled down
      let mainPage = document.getElementById("mainPage")
      const handleScroll = () => {
        if (mainPage.scrollY > 100) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      };
  
      mainPage.addEventListener('scroll', handleScroll);
  
      return () => {
        mainPage.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const scrollToTop = () => {
        let mainPage = document.getElementById("mainPage")
        mainPage.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
  return (
    <div id="mainPage" className='position-relative py-3 w-100' style={{height: "calc(100vh - 56px)", overflowY: 'auto', overflowX: 'hidden'}}>
        <Routes>
            {routes.map((route, idx) => {
                return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} element={<route.component {...props} />} />)
            : (null);
            },)}
            <Route path="*" element={<Navigate to="/" replace />}/>
        </Routes>
        {pathname === "/machine" && 
            <button className="btn btn-primary position-fixed" style={{bottom: 80, right: 40}} onClick={scrollToTop}><i className='fa fa-arrow-up'></i></button>
        }
    </div>
  )
}

export default Main