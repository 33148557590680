import React, { useEffect, useState } from 'react'
import { postRequest, putRequest } from '../../services/events'
import { machineAPI, machinesAPI } from '../../services/serverAddresses'

function AddMachine(props) {
  const [machine, setMachine] = useState({
    mac: "",
    name: "",
    qrCode: "",
    boxes: null,
  })
  const [isLoader, setIsLoader] = useState(false)
  const [editData, setEditData] = useState(null)

  useEffect(() => {
    if(props?.editMachine){
      setEditData(props.editMachine)
      setMachine({
        mac: props.editMachine.mac,
        name: props.editMachine.name,
        qrCode: props.editMachine.qrCode,
        boxes: props.editMachine.boxes,
      })
    }
  }, [])
  

  const onChangeHandler = (e) => {
    let machine1 = {...machine}
    let value = e.target.value
    if(e.target.type === "number"){
      value = parseInt(value) 
    }
    machine1[e.target.name] = value
    setMachine(machine1)
  }
  
  const addMachineHandler = async (e) => {
    e.preventDefault();
    setIsLoader(true)
    if(editData){
      let body = {
        name: machine.name,
        qrCode: machine.qrCode,
        boxes: machine.boxes,
      }
      let response = await putRequest(machineAPI(editData._id),body)
      props.pushAlert({type: "success",heading:"Success", message: "Machine has been updated Successfully!"})
      props.onClose(response)
    }else{
      let response = await postRequest(machinesAPI,machine)
      props.pushAlert({type: "success",heading:"Success", message: "Machine has been added Successfully!"})
    }
    setMachine({
      mac: "",
      name: "",
      qrCode: "",
      boxes: null,
    })
    document.getElementById("addMachine").reset()
    setIsLoader(false)
  }
  
  return (
    <div className='d-flex justify-content-center p-3'>
      <div className='col-md-8 shadow rounded p-5 bg-white'>
        <div className='display-6 mb-3'>{editData?"Update":"Create"} Machine</div>
        <form id="addMachine" className='row' onSubmit={addMachineHandler}>
          <div className="form-group col-md-6 mb-3">
            <label
              htmlFor="machineMac"
              className="col-form-label"
            >
              Machine Mac
            </label>{" "}
            <input
              id="machineMac"
              type="text"
              name="mac"
              defaultValue={machine.mac}
              required="required"
              autoFocus="autofocus"
              className="form-control"
              autoComplete='off'
              placeholder='E.g. 1234567890'
              onChange={onChangeHandler}
              disabled={editData?true:false}
            />
          </div>
          <div className="form-group col-md-6 mb-3">
            <label
              htmlFor="machineName"
              className="col-form-label"
            >
              Machine Name
            </label>{" "}
            <input
              id="machineName"
              type="text"
              name="name"
              defaultValue={machine.name}
              required="required"
              className="form-control"
              autoComplete='off'
              placeholder='E.g. M022'
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group col-md-6 mb-3">
            <label
              htmlFor="qrCode"
              className="col-form-label"
            >
              QR Code
            </label>{" "}
            <input
              id="qrCode"
              type="text"
              name="qrCode"
              defaultValue={machine.qrCode}
              required="required"
              className="form-control"
              autoComplete='off'
              placeholder='E.g. M022'
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group col-md-6 mb-3">
            <label
              htmlFor="noOfBox"
              className="col-form-label"
            >
              No. Of Boxes
            </label>{" "}
            <input
              id="noOfBox"
              type="number"
              min="1"
              name="boxes"
              defaultValue={machine.boxes}
              required="required"
              className="form-control"
              autoComplete='off'
              placeholder='E.g. 23'
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group my-3 d-flex justify-content-end">
            {isLoader?
            <button type='button' className='btn btn-success'>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
            </button>
            :
            <div>
              {editData && 
                <><button type='button' onClick={()=>props.onClose()} className='btn btn-light'>Cancel</button>{" "}</>
              }
              <button type='submit' className='btn btn-success'>Save</button>
            </div>
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddMachine