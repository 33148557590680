import React, { useEffect, useState } from 'react'
import { getRequest, putRequest } from '../../services/events'
import { assignMachineAPI, getVendorsAPI, machinesAPI, unassignedMachinesAPI } from '../../services/serverAddresses'
function ConnectMachine(props) {
  const [users, setUsers] = useState([])
  const [isLoader, setIsLoader] = useState(true)
  // const [machineListAll, setMachineListAll] = useState([])
  const [machineList, setMachineList] = useState([])
  const [isLoader1, setIsLoader1] = useState(true)
  const [userSelect, setUserSelect] = useState(null)

  useEffect(() => {
    fetchUser()
    fetchMachine()
  }, [])
  

  const fetchUser = async() => {
    setIsLoader(true)
    let response = await getRequest(getVendorsAPI)
    setUsers(response)
    setIsLoader(false)
  }
  const fetchMachine = async () => {
    setIsLoader1(true)
    let response = await getRequest(unassignedMachinesAPI)
    setMachineList(response)
    setIsLoader1(false)
  }
  const onChangeHandler = (e) => {
    if(e.target.name === "machineAdmin"){
     if(e.target.selectedIndex){
      setUserSelect(users[e.target.selectedIndex - 1])
     }
    }
    else if(e.target.name === "machines"){
     if(e.target.value){
      let userSelect1 = {...userSelect}
      userSelect1['machines'].push(JSON.parse(e.target.value))
      setUserSelect(userSelect1)
     }
    }
    else if(e.target.name === "deleteMachine"){
     if(e.target.value >= 0){
      let userSelect1 = {...userSelect}
      userSelect1['machines'].splice(e.target.value,1)
      setUserSelect(userSelect1)
     }
    }
  }
  const onSaveHandler = async (e) => {
    e.preventDefault();
    if(userSelect){
      let machineList = userSelect.machines.map(machine => machine._id)
      let response = await putRequest(assignMachineAPI(userSelect._id),machineList)
      props.pushAlert({type: "success",heading:"Success", message: "Machine has been connected Successfully!"})
    }
  }
  return (
    <div className='d-flex justify-content-center p-3'>
      <div className='col-lg-8 col-md-10 shadow rounded p-5'>
        <div className='display-6 mb-3'>Connect Machine</div>
        <form className='row' onSubmit={onSaveHandler}>
          <div className="form-group col-md-12 mb-3">
            <label
              htmlFor="machineAdmin"
              className="col-form-label"
            >
              Machine Admin
            </label>{" "}
            <div className='d-flex align-items-center' style={{gap: 10}}>
              <select
                id="machineAdmin"
                name="machineAdmin"
                defaultValue=""
                className="form-select"
                aria-label="E.g. 00012"
                required="required"
                onChange={onChangeHandler}>
                <option title={"Select Admin"} value="">Select Admin</option>
                {users.map((user,key) =>
                  <option key={key} title={"Email: "+ user._id} value={user._id}>{user.name +" > " + user._id}</option>
                )}
              </select>
              {isLoader && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            </div>
          </div>
          <div className="form-group col-md-12">
            <label
              htmlFor="connectMachine"
              className="col-form-label"
            >
              Machine Mac
            </label>{" "}
            <div className='d-flex align-items-center' style={{gap: 10}}>
              <select
                id="connectMachine"
                name="machines"
                defaultValue=""
                className="form-select"
                aria-label="E.g. M011"
                required="required"
                onChange={onChangeHandler}
                >
                <option title={"Select Machine"} value="">Select Machines</option>
                {machineList.map((machine,key) =>
                !userSelect?.machines?.includes(machine._id) &&
                  <option key={key} title={"Mac: "+ machine.mac} value={JSON.stringify(machine)}>{machine.name + " > " + machine.mac}</option>
                )}
              </select>
              {" "}
              {isLoader1 && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            </div>
            <div>
              <div className='d-flex flex-wrap' style={{gap: 5}}>
                {userSelect?.machines.map((machine,key) => 
                  // machineListAll.map(machMachine => machine === machMachine._id &&
                    <div key={key} className='border border-light mt-1 rounded-1 p-1'><span className='px-2'>{machine.name}</span> 
                    {/* <button type='button' onClick={()=> onChangeHandler({target:{name: 'deleteMachine',value: key}})} className='btn btn-sm btn-light rounded-1' style={{padding: '5px 10px'}}><i className="fa fa-times"></i></button> */}
                    </div>
                  // )
                )}
              </div>
            </div>
          </div>
          <div className="form-group my-3 d-flex justify-content-end">
            <button type='submit' className='btn btn-success'>Save</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ConnectMachine