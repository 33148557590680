import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { addUserAPI, unassignedMachinesAPI } from '../../services/serverAddresses'
import { getRequest, postRequest } from '../../services/events'

function AddAdmin(props) {
  let {pathname} = useLocation();
  const [user, setUser] = useState({
    _id: "",
    password: "",
    name: "",
    role: "Vendor",
    machines: [],
  })
  const [retypePassword, setRetypePassword] = useState("")
  const [machineList, setMachineList] = useState([])
  const [isLoader, setIsLoader] = useState(true)
  const [isSubmitLoader, setIsSubmitLoader] = useState(false)
  const [vision, setVision] = useState(false)
  
  useEffect(() => {
    setUser({
      _id: "",
      password: "",
      name: "",
      role: pathname === "/addadmin"? "Vendor" : "Customer",
      machines: [],
    })
    async function fetchData() {
      let response = await getRequest(unassignedMachinesAPI)
      setMachineList(response)
      setIsLoader(false)
    }
    if(pathname === "/addadmin"){
      fetchData()
    }
  }, [pathname])
  
  const onChangeHandler = (e) => {
    let user1 = {...user},
    type = e.target.type,
    value = e.target.value,
    name = e.target.name
    if(name === "machines"){
      user1[name].push(value)
    }
    else if(name === "deleteMachine"){
      user1['machines'].splice(value,1)
    }else{
      user1[name] = type !== "number"? value : parseInt(value) 
    }
    setUser(user1)
  }

  const addAdminHandler = async (e) => {
    e.preventDefault();
    setIsSubmitLoader(true)
    let response = await postRequest(addUserAPI,user)
    props.pushAlert({type: "success",heading:"Success", message: (pathname === "/addadmin"? "Admin Machine" : "Customer ")+" has been added Successfully!"})
    setUser({
      _id: "",
      password: "",
      name: "",
      role: pathname === "/addadmin"? "Vendor" : "Customer",
      machines: [],
    })
    setRetypePassword("")
    document.getElementById("addUser").reset()
    setIsSubmitLoader(false)
  }
  return (
    <div className='d-flex justify-content-center p-3'>
      <div className='col-lg-8 col-md-10 shadow rounded p-5'>
        <div className='display-6 mb-3'>Create {pathname === "/addadmin"? "Admin Machine" : "Customer "}</div>
        <form id="addUser" className='row g-3' onSubmit={addAdminHandler}>
          <div className="form-group col-md-6">
            <label
              htmlFor="machineAdmin"
              className="col-form-label"
            >
              {pathname === "/addadmin"? "Machine Admin" : "Customer Name"}
            </label>{" "}
            <input
              id="machineAdmin"
              type="text"
              name="name"
              defaultValue=""
              required="required"
              autoFocus="autofocus"
              autoComplete='off'
              className="form-control "
              placeholder={pathname === "/addadmin"? "Machine Admin" : "Customer Name"}
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group col-md-6">
            <label
              htmlFor="email"
              className="col-form-label"
            >
              Email
            </label>{" "}
            <input
              id="email"
              type="email"
              name="_id"
              defaultValue=""
              required="required"
              autoComplete='off'
              className="form-control "
              placeholder='Email'
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group col-md-6">
            <label
              htmlFor="password"
              className="col-form-label"
            >
              Password
            </label>{" "}
              <div className="input-group">
              <input
                id="password"
                type={vision?"text":"password"}
                name="password"
                defaultValue=""
                required="required"
                autoComplete='off'
                className="form-control "
                placeholder='Password'
                onChange={onChangeHandler}
              />
              <button onClick={()=>setVision(!vision)} className="btn btn-light" type="button"><i className={'fa fa-eye'+(vision?'-slash':'')}></i></button>
            </div>
            {retypePassword && retypePassword !== user.password && <small className='text-danger'>Password not match.</small>}
          </div>
          <div className="form-group col-md-6">
            <label
              htmlFor="confirmpassword"
              className="col-form-label"
            >
              Confirm Password
            </label>{" "}
            <div className="input-group">
              <input
                id="confirmpassword"
                type={vision?"text":"password"}
                name="retypePassword"
                defaultValue=""
                required="required"
                autoComplete='off'
                className="form-control "
                placeholder='Confirm Password'
                onChange={(e)=>setRetypePassword(e.target.value)}
                
              />
              <button onClick={()=>setVision(!vision)} className="btn btn-light" type="button"><i className={'fa fa-eye'+(vision?'-slash':'')}></i></button>
            </div>
            {retypePassword && retypePassword !== user.password && <small className='text-danger'>Password not match.</small>}
          </div>
          {pathname === "/addadmin" &&
          <div className="form-group col-md-12">
            <label
              htmlFor="connectMachine"
              className="col-form-label"
            >
              Connect Machines
            </label>{" "}
            <div className='d-flex align-items-center' style={{gap: 10}}>
              <select
                id="connectMachine"
                name="machines"
                defaultValue=""
                className="form-select"
                aria-label="E.g. M011"
                onChange={onChangeHandler}>
                <option title={"Select Machine"} value="">Select Machines</option>
                {machineList.map((machine,key) => !user.machines.includes(machine._id) &&
                  <option key={key} title={"Mac: "+ machine.mac} value={machine._id}>{machine.name + " > " + machine.mac}</option>
                )}
              </select>
              {" "}
              {isLoader && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            </div>
            <div>
              <div className='d-flex flex-wrap' style={{gap: 5}}>
                {user.machines.map((machine,key) => 
                  machineList.map(machMachine => machine === machMachine._id &&
                    <div key={key} className='border border-light mt-1 rounded-1 p-1'><span className='px-2'>{machMachine.name}</span> <button type='button' onClick={()=> onChangeHandler({target:{name: 'deleteMachine',value: key}})} className='btn btn-sm btn-light rounded-1' style={{padding: '5px 10px'}}><i className="fa fa-times"></i></button></div>
                  )
                )}
              </div>
            </div>
          </div>}
          <div className="form-group my-3 d-flex justify-content-end">
            {isSubmitLoader?
              <button type='button' className='btn btn-success'>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
              </button>
            :
              <button type='submit' className='btn btn-success'>Save</button>
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddAdmin