import React, { useEffect, useState } from 'react'
import { postRequest } from '../../services/events'
import { signInAddress, signUpAddress } from '../../services/serverAddresses'
import Cookies from 'js-cookie'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'


function Login() {
  let {pathname} = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(pathname === "login"? {
    _id: "",
    password: "",
    rememberMe: false, 
  }:{
    _id: "",
    password: "",
    name: "",
    role: "Customer",
    machines: [],
  }
  )
  const [retypePassword, setRetypePassword] = useState("")
  const [isLoader, setIsLoader] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const [vision, setVision] = useState(false)
  useEffect(() => {
    setIsLogin(pathname !== "/registration")
    setUser(pathname === "login"? {
      _id: "",
      password: "",
      rememberMe: false, 
    }:{
      _id: "",
      password: "",
      name: "",
      role: "Customer",
      machines: [],
    }
    )
    setRetypePassword("")
  }, [pathname])
  
  useEffect(() => {
    let cookies = Cookies.get('user')
    if(cookies){
      cookies = JSON.parse(cookies)
      navigate(cookies.role === "Customer"?"/machinescan":"/machines")
    }
  }, [])

  const onChangeHandler = (e) => {
    let user1 = {...user}
    if(e.target.name === "rememberMe"){
      user1[e.target.name] = e.target.checked
    }else{
      user1[e.target.name] = e.target.value
    }
    setUser(user1)
  }
  const signInHandler = async (e) => {
    e.preventDefault();
    setIsLoader(true)
    let response = await postRequest(signInAddress, user)
    if(!response.statusCode){
      let expiresIn = response.expiresIn
          expiresIn = expiresIn/60/60/24
      expiresIn = moment().add(expiresIn,'days')
      expiresIn = new Date(expiresIn).getTime()
      let role = response.role
      response.expiresIn = expiresIn
      response = JSON.stringify(response, expiresIn)
      Cookies.set('user', response, expiresIn)
      setIsLoader(false)
      navigate(role === "Customer"?"/machinescan":"/machines")
    }else{
      alert(response.message)
      setIsLoader(false)
    }
  }
  const signUpHandler = async (e) => {
    e.preventDefault();
    setIsLoader(true)
    let response = await postRequest(signUpAddress,user)
    // props.pushAlert({type: "success",message:"Success", message: "Account has been Registered Successfully!"})
    setRetypePassword("")
    setUser({
      _id: "",
      password: "",
      name: "",
      role: "Customer",
      machines: [],
    })
    setIsLoader(false)
    alert("Account has been Registered Successfully!")
    navigate('/login')
  }
  
  
  return (
    <div className="container py-4">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <form className="card" onSubmit={isLogin?signInHandler:signUpHandler}>
            <div className="card-header d-flex justify-content-between"><span>{isLogin?"Login":"Sign Up As Customer"}</span><Link to={isLogin?"/registration":"/login"}>{isLogin? "Become a customer":"Back to login"}</Link></div>
            <div className="card-body">
              {!isLogin && 
                <div className="form-group row mb-3">
                  <label
                    htmlFor="name"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Name
                  </label>{" "}
                  <div className="col-md-6">
                    <input
                      id="name"
                      type="text"
                      name="name"
                      defaultValue=""
                      required="required"
                      autoComplete="name"
                      autoFocus="autofocus"
                      className="form-control "
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
              }
              <div className="form-group row mb-3">
                <label
                  htmlFor="email"
                  className="col-md-4 col-form-label text-md-right"
                >
                  E-Mail Address
                </label>{" "}
                <div className="col-md-6">
                  <input
                    id="email"
                    type="email"
                    name="_id"
                    defaultValue=""
                    required="required"
                    autoComplete="email"
                    autoFocus={isLogin?"autofocus":"false"}
                    className="form-control "
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <div className="form-group row mb-3">
                <label
                  htmlFor="password"
                  className="col-md-4 col-form-label text-md-right"
                >
                  Password
                </label>{" "}
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      id="password"
                      type={vision?"text":"password"}
                      name="password"
                      required="required"
                      className="form-control "
                      onChange={onChangeHandler}
                    />
                    <button onClick={()=>setVision(!vision)} className="btn btn-light" type="button"><i className={'fa fa-eye'+(vision?'-slash':'')}></i></button>
                  </div>
                  {retypePassword && retypePassword !== user.password && <small className='text-danger'>Password not match.</small>}
                </div>
              </div>
              {!isLogin && 
                <div className="form-group row mb-3">
                  <label
                    htmlFor="repassword"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Confirm Password
                  </label>{" "}
                  <div className="col-md-6">
                    <div className="input-group">
                      <input
                        id="repassword"
                        type={vision?"text":"password"}
                        name="repassword"
                        required="required"
                        className="form-control "
                        onChange={(e)=>setRetypePassword(e.target.value)}
                      />
                      <button onClick={()=>setVision(!vision)} className="btn btn-light" type="button"><i className={'fa fa-eye'+(vision?'-slash':'')}></i></button>
                    </div>
                    {retypePassword && retypePassword !== user.password && <small className='text-danger'>Password not match.</small>}
                  </div>
                </div>
              }
              {isLogin &&<div className="form-group row mb-3">
                <div className="col-md-6 offset-md-4">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="rememberMe"
                      id="remember"
                      className="form-check-input"
                      onChange={onChangeHandler}
                    />{" "}
                    <label htmlFor="remember" className="form-check-label">
                      Remember Me
                    </label>
                  </div>
                </div>
              </div>}
              <div className="form-group row mb-0">
                <div className="col-md-8 offset-md-4">
                  <button type="submit" className="btn btn-primary">
                    {isLoader?
                      <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Logging in...</>
                    :
                    isLogin?"Login":"Sign Up"
                    }
                  </button>{" "}
                {/* {isLogin &&
                  <a
                    href=""
                    className="btn btn-link"
                  >
                    Forgot Your Password?
                  </a>} */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login