import React, { useEffect, useState } from 'react'
import { baseUrl, productAPI, productsAPI } from '../../services/serverAddresses'
import { postFormDataRequest, putFormDataRequest } from '../../services/events'

function AddProduct(props) {
  const [imgFile, setImgFile] = useState(null)
  const [data, setData] = useState({name: null, barCode: null, price: null})
  const [isLoader, setIsLoader] = useState(false)
  const [editData, setEditData] = useState(null)
  const [directLink, setDirectLink] = useState(false)

  useEffect(() => {
    if(props?.editProduct){
      setEditData(props.editProduct)
      setData({
        name: props.editProduct.name,
        barCode: props.editProduct.barCode,
        price: props.editProduct.price,
      })
      setDirectLink(true)
      setImgFile(baseUrl+props.editProduct.image)
    }
  }, [])
  const onChangeHandler = (e) => {
    let name = e.target.name,
    value = e.target.value,
    data1 = {...data}
    data1[name] = value
    setData(data1)
  }
  const checkImageProperties = async (file) => {
    // setLogoLoader(true)
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = async () => {
                // const width = image.width;
                // const height = image.height;
                const size = file.size;
                const fileType = file.type;
                // check if the image meets your criteria for size, width, height, and type
                if (size > 1024 * 1024) { // max size 1MB
                    // setLogoLoader(false)
                    reject("File size too large. Please select a smaller image.");
                    // } else if (width !== 200 || height !== 100) { // required dimensions 300x100
                    //   reject("Invalid image dimensions. Please select an image with dimensions of 300x100 pixels.");
                } else if (!fileType.startsWith("image/")) { // check if the file type is an image
                    // setLogoLoader(false)
                    reject("Invalid file type. Please select an image file.");
                } else {
                    // let res = await postImage(file)
                    // setImgURL(res?.filename)
                    // setLogoLoader(false)
                    resolve();
                }
            };
        };
        reader.readAsDataURL(file);
    });
  }
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoader(true)
    const formData = new FormData();
    if(!directLink){
      formData.append('image', imgFile);
    }
    formData.append('data', JSON.stringify(data));
    if(editData){
      let response = await putFormDataRequest(productsAPI(editData._id), formData)
      props.onClose(response)
    }else{
      let response = await postFormDataRequest(productAPI, formData)
    }
    setImgFile(null)
    setData({name: null, barCode: null, price: null})
    props.pushAlert({type: "success",heading:"Success", message: "Product has been "+(editData?"updated":"added")+" Successfully!"})
    document.getElementById("addProduct").reset()
    setIsLoader(false)
  }
  return (
    <div className='d-flex justify-content-center p-3'>
      <div className='col-lg-8 col-md-10 shadow rounded bg-white p-5'>
        <div className='display-6 mb-3'>{editData?"Update":"Create"} Product</div>
        <form id="addProduct" className='row' onSubmit={onSubmitHandler}>
          <div className="form-group col-md-12 mb-3 d-flex justify-content-center py-3">
            <label htmlFor='imageUpload'>
              <img className='rounded-3' style={{border: "1px solid black", width: '200px'}} src={imgFile?directLink?imgFile:URL.createObjectURL(imgFile):'https://st3.depositphotos.com/15827064/18517/v/600/depositphotos_185177304-stock-illustration-image-photo-photography-picture-upload.jpg'}/>
              <input type='file' accept="image/*" id="imageUpload" onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    if(file.type.endsWith("jpg") || file.type.endsWith("jpeg") || file.type.endsWith("png")){
                      checkImageProperties(file)
                          .then(() => {
                              setDirectLink(false)
                              setImgFile(file)
                          })
                          .catch((error) => {
                              alert(error);
                            });
                        }else{
                          alert("Invalid Image");
                        }
                  }
              }} hidden/>
            </label>
          </div>
          <div className="form-group col-md-12 mb-3">
            <label
              htmlFor="name"
              className="col-form-label"
            >
              Name
            </label>{" "}
            <input
              id="name"
              type="text"
              name="name"
              defaultValue={data.name}
              required="required"
              autoFocus="autofocus"
              className="form-control "
              placeholder='E.g. Bottles'
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group col-md-6 mb-3">
            <label
              htmlFor="code"
              className="col-form-label"
            >
              Bar Code
            </label>{" "}
            <input
              id="code"
              type="text"
              name="barCode"
              defaultValue={data.barCode}
              required="required"
              className="form-control "
              placeholder='E.g. 12334343'
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group col-md-6 mb-3">
            <label
              htmlFor="code"
              className="col-form-label"
            >
              Price
            </label>{" "}
            <input
              id="price"
              type="text"
              name="price"
              defaultValue={data.price}
              required="required"
              className="form-control "
              placeholder='E.g. 60'
              onChange={onChangeHandler}
            />
          </div>
          <div className="form-group my-3 d-flex justify-content-end">
            {isLoader?
            <button type='button' className='btn btn-success'>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
            </button>
            :
            <div>
              {editData && 
                <><button type='button' onClick={()=>props.onClose()} className='btn btn-light'>Cancel</button>{" "}</>
              }
              <button type='submit' className='btn btn-success'>Save</button>
            </div>
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddProduct