// export const baseUrl = "http://45.149.206.229:8081/" // "http://192.168.2.150:8081/"
export const baseUrl = "https://server.moaddi.site/" // "https://8bbbc84e-2514-4579-b2f1-f3333aebb0ec-00-39d8f23jz5chb.sisko.replit.dev/" //"https://moaddiv2.najabhaider.repl.co/"
export const address = baseUrl + "api/v1/"
export const socketAddress = baseUrl

export const signInAddress = address + "users/signin"
export const signUpAddress = address + "users/signup"
export const getVendorsAPI = address + "users/role/Vendor"
export const getCustomerAPI = address + "users/role/Customer"
export const addUserAPI = address + "users/create"
export const machinesAPI = address + "machines"
export const unassignedMachinesAPI = machinesAPI + "/all"
export const productAPI = address + "products"
export const purchasesAPI = address + "purchases"
export const allPendingRequests = address + "purchases/allpendingrequests"
export const getCertificatesAPI = address + "broker/generatecerts"

export const userAPI = (id) => address + "users/" + id
export const userToggleAPI = (id) => address + "users/" + id + "/toggle"
export const machineAPI = (id) => address + "machines/" + id
export const machineToggleAPI = (id) => address + "machines/" + id + "/toggle"
export const machineQRScane = (id) => address + "machines/qrcode/" + id
export const assignMachineAPI = (id) => address + "machines/vendor/" + id + "/assign"
export const unassignMachineAPI = (id) => address + "machines/" + id + "/unassign"
export const boxUpdateAPI = (id) => address + "boxes/product/" + id + "/changeproduct"
export const unassignBoxAPI = (id) => address + "boxes/machine/" + id + "/unassign"
export const productsAPI = (id) => address + "products/" + id
