import JsBarcode from 'jsbarcode';
import React, { useEffect, useState } from 'react'
import { deleteRequest, getRequest } from '../../services/events';
import { baseUrl, productAPI, productsAPI } from '../../services/serverAddresses';
import Popup from '../../component/popup';
import AddProduct from './addProduct';

function ProductList(props) {
  const [products, setProducts] = useState([])
  const [isLoader, setIsLoader] = useState(true)
  const [isUDLoader, setIsUDLoader] = useState(false)
  const [editData, setEditData] = useState(null)
  const [confirmPopup, setConfirmPopup] = useState(null)
  useEffect(() => {
    fetchProduct()
  }, [])
  useEffect(() => {
    JsBarcode(".barcode").init();
  }, [products])
  const fetchProduct = async ()=>{
    setIsLoader(true)
    let response = await getRequest(productAPI)
    setProducts(response)
    setIsLoader(false)
  }
  const onDeleteHandler = async () => {
    let _id = confirmPopup._id, index = confirmPopup.index;
    let products1 = [...products]
    setIsUDLoader(true)
    let response = await deleteRequest(productsAPI(_id))
    props.pushAlert({type: "warning",heading:"Delete", message: "Product has been deleted!"})
    products1.splice(index,1)
    setProducts(products1)
    setConfirmPopup(null)
    setIsUDLoader(false)
  }
  const popupOnClose = (e) => {
      setEditData(null)
      if(e){
          let products1 = [...products]
          products1.map((product,key) => {
              if(product._id === e._id){
                  products1[key] = {...e}
              }
          })
          setProducts(products1)
      }
  }
  
  return (
    <div className='p-3'>
        <div className='card'>
            <div className='card-header'>
                Product List
            </div>
            
            <div className='card-body'>
            {/* table-striped */}
                <table className="product-table table table-bordered text-center">
                    <thead className="table-primary">
                        <tr>
                          <th scope="col">#</th>
                          <th className="align-middle" scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Price</th>
                          <th scope="col">Code</th>
                          <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoader? products.map((product, key) =>
                        <tr key={key}>
                          <th className="align-middle" scope="row">{key+1}</th>
                          <td className="align-middle"><img height={100} src={baseUrl+product.image}/></td>
                          {/* <td className="align-middle"><img height={100} src={baseUrl+product.image} alt={product.name}/></td> */}
                          <td className="align-middle">{product.name}</td>
                          <th className="align-middle">{product.price} SAR</th>
                          <td className="align-middle">
                            <svg className="barcode"
                              jsbarcode-value={product.barCode}
                              jsbarcode-textmargin="0"
                              >
                            </svg>
                          </td>
                          <td className="align-middle">
                            {isUDLoader?
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            :<>
                              <button onClick={()=>setEditData(product)} className='btn btn-sm btn-light text-info'> <i className='fa fa-pen'></i> </button> &nbsp;
                              <button onClick={()=>setConfirmPopup({_id: product._id, index: key})} className='btn btn-sm btn-light text-danger'> <i className='fa fa-trash'></i> </button>
                            </>}
                          </td>
                        </tr>
                        ): <tr><td colSpan={4} className='text-center'><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
        {editData && <Popup isCloseBtn={true} onClose={()=>setEditData(null)}>
          <div></div>
            <AddProduct {...props} onClose={popupOnClose} editProduct={editData}/>
        </Popup>}
        {confirmPopup !== null && <Popup>
            <div className='w-100 mx-1' style={{maxWidth: 600, minWidth: 300}}>
                <div className='card'>
                    <div className='card-header bg-danger text-light'><i className="fa-solid fa-exclamation"></i></div>
                    <div className='card-body'>
                        <h5 className='text-center py-3'>Are you sure?</h5>
                        <div className='d-flex justify-content-center' style={{gap: 10}}>
                        {isUDLoader?
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        :<>
                            <button onClick={()=> setConfirmPopup(null)} style={{width: 100}} className='btn btn-light'><i className="fa-regular fa-face-smile"></i> No</button>
                            <button onClick={()=> onDeleteHandler()} style={{width: 100}} className='btn btn-danger'><i className="fa-regular fa-face-frown"></i> Yes</button>
                        </>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </Popup>}
    </div>
  )
}

export default ProductList