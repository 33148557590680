import axios from "axios";
import Cookies from "js-cookie";
import { signInAddress } from "./serverAddresses";


export const postFormDataRequest = async (url, body) => {
    if(url !== signInAddress){
        let cookies = Cookies.get('user')
        if(cookies){
            cookies = JSON.parse(cookies)
            axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.token;
            axios.defaults.headers['Content-Type'] = 'multipart/form-data';
        }
    }
    try {
        const response = await axios.post(url, body);
        return response.data
    } catch (error) {
        return error.response.data
    }
}
export const postRequest = async (url, body = null) => {
    if(url !== signInAddress){
        let cookies = Cookies.get('user')
        if(cookies){
            cookies = JSON.parse(cookies)
            axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.token;
        }
    }
    try {
        let response
        if(body){
            response = await axios.post(url, body);
        }else{
            response = await axios.post(url);
        }
        return response.data
    } catch (error) {
        return error.response.data
    }
}
export const getRequest = async (url) => {
    let cookies = Cookies.get('user')
    if(cookies){
        cookies = JSON.parse(cookies)
        axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.token;
    }
    try {
        const response = await axios.get(url);
        return response.data
    } catch (error) {
        let errorMsg = null
        if(error?.response?.data){
            errorMsg = error?.response?.data
        }else{
            errorMsg = "something went wrong!"
        }
        return errorMsg
    }
}

export const putFormDataRequest = async (url, body) => {
    let cookies = Cookies.get('user')
    if(cookies){
        cookies = JSON.parse(cookies)
        axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.token;
        axios.defaults.headers['Content-Type'] = 'multipart/form-data';
    }
    try {
        const response = await axios.put(url, body);
        return response?.data
    } catch (error) {
        return error?.response?.data
    }
}

export const putRequest = async (url, body) => {
    let cookies = Cookies.get('user')
    if(cookies){
        cookies = JSON.parse(cookies)
        axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.token;
    }
    try {
        const response = await axios.put(url, body);
        return response?.data
    } catch (error) {
        return error?.response?.data
    }
}

export const deleteRequest = async (url) => {
    let cookies = Cookies.get('user')
    if(cookies){
        cookies = JSON.parse(cookies)
        axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.token;
    }
    try {
        const response = await axios.delete(url);
        return response?.data
    } catch (error) {
        return error?.response?.data
    }
}